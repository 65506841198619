import $ from "jquery";
import { getSize } from "../../common/layout/useWindowSize";

export function adsViews(url, params) {
	let adsViewsInterval = setInterval(function () {
		if ($(".native-ad-image").length > 0) {
			params.view = getView();
			$.get(url, params);
			clearInterval(adsViewsInterval);
		}
	}, 1000);
}

function getView() {
	const { isMobile } = getSize();
	return isMobile ? "mobile" : "desktop";
}
