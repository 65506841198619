import { getMessagesMargin } from "./common/toasts/messagesMargin";
import { addBackdropOverlay, waitForCookiesBanner } from "./common/utils";

export function industryTypeAndJobBanner() {
	document.addEventListener(
		"DOMContentLoaded",
		() => {
			const banner = document.getElementById("industry-and-job-modal");

			if (banner) {
				addBackdropOverlay();
				waitForCookiesBanner(
					(...args) => onReady(banner, ...args),
					onClose,
				);

				function onReady(banner, height) {
					const margin = getMessagesMargin();
					if (window.innerWidth < 768) {
						banner.style.bottom = height + margin + "px";
					} else {
						banner.style.bottom = height + margin * 2 + "px";
					}
				}

				function onClose() {
					banner.style.bottom = "";
				}
			}
		},
		false,
	);
}
